.margin20 {
  margin: 20px;
}

.orderItem {
  margin-left: 1px;
  margin-bottom: 1px;
  min-width: 8em;
  text-align: center;
  padding-top: 1%;
  padding-bottom: 1%;
}

.orderItemTitle {
  margin-left: 1px;
  margin-bottom: 1px;
  min-width: 8em;
  text-align: center;
  padding-top: 1%;
  padding-bottom: 1%;
  color: #003737;
  font-weight: bold;
  font-size: 1.5em;
}

.displayNone {
  display: none !important;
}

#listWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 15em;
  height: 2em;
}

#viewDetails {
  background-color: #003732;
  color: white;
}

#viewDetails:hover {
  cursor: pointer;
}

#detailsButton {
  box-shadow: 0 0 5px lightgray;
  background-color: #FFFFFF;
  color: #d2c3ff;
}

#detailsButton:hover {
  background-color: #d2c3ff;
  color: #FFFFFF;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5em;
}

.newOrderButtonDisabled {
  background-color: gray;
}

.newOrderButton {
  background-color: #d2c3ff;
}

.newOrderButton:hover {
  background-color: #003939;
}

.arrow {
  color: black;
}

.arrow:hover {
  color: white;
}

.newOrderButton:hover > .arrow {
  color: white;
}

@keyframes spin {
  0% {
    transform: perspective(0px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(0px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(0px) rotateX(0deg) rotateY(180.1deg);
    -webkit-transform: perspective(0px) rotateX(0deg) rotateY(180.1deg);
  }
  150% {
    transform: perspective(0px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(0px) rotateX(0deg) rotateY(0deg);
  }
}

.horizontalCard {
  min-width: 20em;
  min-height: 15em;
  border-color: black;
  border-style: solid;
  display: flex;
  border-radius: 2em;
  align-items: center;
}

.horizontalCardForSmartphone {
  width: 300px;
  border-color: black;
  border-style: solid;
  border-radius: 2em;
  margin-top: auto;
  margin-bottom: auto;
}

.verticalCard {
  border-color: black;
  border-style: solid;
  display: flex;
  align-items: center;
  margin-left: 1em;
  border-radius: 2em;
  margin-top: 1em;
}

.verticalCenter {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 400px
}

#text {
  margin-left: auto;
  margin-right: auto;
}

.flex-container {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

#colorGridItem {
  width: 6vw;
  height: 6vw;
  border-style: solid;
  border-color: transparent;
  border-width: 3px;
  margin: 0.1em;
}

#textureGridItem {
}

#textureGridItem:hover {
  cursor: pointer;
}

#colorGridtem:hover{
  cursor: pointer;
}


.loader {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.informationComponent {
  width: 64%;
}

.cardGridWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.marginAuto {
  margin-left: auto;
  margin-right: auto;
}

.cardWrapper {
  display: flex;
  flexDirection: column;
}

.pointer:hover {
  cursor: pointer;
}

.button {
  background-color: #d2c3ff !important;
  width: 100%;
  min-height: 2em;
  display: flex;
}

.button:hover {
  background-color: #003939 !important;
}

.buttontext {
  color: black;
}

.buttonText:hover {
  color: white;
}

.textAreaLeft {
  border: none;
  background-color: transparent;
  resize: none;
  outline: none;
}

.textAreaRight {
  border: none;
  background-color: transparent;
  resize: none;
  outline: none;
  white-space: normal;
  text-align: right;
  -moz-text-align-last: right; /* Firefox 12+ */
  text-align-last: right;
}

.textAreaCenter {
  border: none;
  background-color: transparent;
  resize: none;
  outline: none;
  white-space: normal;
  text-align: center;
  -moz-text-align-last: center; /* Firefox 12+ */
  text-align-last: center;
}

.validationContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.c-article__link {
  display: inline-block;
  font-family: TradeGothic,sans-serif;
  font-size: 20px;
  color: #00373c;
  position: relative;
  padding-bottom: 7px;
}

.c-article__link:hover {
  color: #00373c;
}

.c-article__link::after {
  display: block;
  content: '';
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: auto;
  background-color: #00373c;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.c-article__link:hover::after {
  width: 0;
  left: auto;
  right: 0;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.c-article__link.noAfter::after {
  display: none;
}

.editThumbSize .MuiSlider-thumb {
  width: 18px;
  height: 18px;
  margin-top: -8px;
}

.c-formulaire_field_outlined .MuiInputBase-root,.c-formulaire_field_outlined .MuiFormLabel-root, .c-formulaire_field_outlined textarea {
  font-family: Axiforma;
  margin-bottom: 15px;
  font-size: 14px;
}

.c-formulaire_field_outlined textarea {
  width: 100% !important;
  border-color: rgb(0, 57, 57);
  border-radius: 3px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
}

.c-formulaire_field_outlined .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: rgb(0, 57, 57);
}

.c-formulaire_field_outlined .MuiOutlinedInput-notchedOutline {
  border-color: rgb(0, 57, 57) !important;
}

.c-formulaire_field_outlined .MuiFormLabel-root {
  color: rgb(0, 57, 57);
}

.c-formulaire_field_outlined  .MuiFormLabel-root.Mui-focused {
  color: rgb(0, 57, 57);
}

.c-formulaire_field_outlined .MuiOutlinedInput-input {
  padding: 10px 14px;
  font-size: 14px;
}

.c-formulaire_field_outlined  .MuiInputLabel-outlined {
  margin-top: -8px;
}

.c-formulaire_field_outlined  .MuiInputLabel-shrink {
  margin: 0 !important;
}

.c-formulaire_field input[type=email], .c-formulaire_field input[type=tel], .c-formulaire_field input[type=text], .c-formulaire_field textarea {
  width: 100%;
  -webkit-transition: all .5s ease;
  -o-transition: all .5s ease;
  transition: all .5s ease;
  border: solid 1px #676e72;
  color: #00373c;
  font-size: 12px;
  padding: 0 15px;
  border-radius: 15px;
  height: 40px;
}

.create-book-photo-input, .create-book-image-preview-icon-container {
  cursor: pointer;
}

.MuiButton-contained:hover {
  background-color: rgb(0, 57, 57) !important;
  color: white;
}
