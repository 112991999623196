@font-face {
  font-family: Nordica;
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/NordicaRegular.ttf') format('opentype');
}

@font-face {
  font-family: NugoSans;
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/NugoSans-Light.ttf') format('opentype');
}

@font-face {
  font-family: Epittazio;
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/Epittazio.ttf') format('opentype');
}

@font-face {
  font-family: FragmentCore;
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/Fragmentcore.ttf') format('opentype');
}

@font-face {
  font-family: ModernSans;
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/ModernSans-Light.ttf') format('opentype');
}

@font-face {
  font-family: Pistara;
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/Pistara.ttf') format('opentype');
}

@font-face {
  font-family: Selya;
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/Selya.ttf') format('opentype');
}

@font-face {
  font-family: Axiforma;
  font-style: normal;
  font-weight: 900;
  src: url('../assets/fonts/AxiformaBlack.otf');
}

@font-face {
  font-family: Axiforma;
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/AxiformaBold.otf');
}

@font-face {
  font-family: Axiforma;
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/AxiformaMedium.otf');
}

@font-face {
  font-family: TradeGothic;
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/tradeGothic.eot') format('opentype');
}

h1 {
  text-align: left;
  width: 100%;
}
h1, h2 {
  font-family: Axiforma, sans-serif;
  font-weight: 900;
}

h3, h4, h5, h6 {
  font-family: Axiforma, sans-serif;
  font-weight: 700;
}

body {
  font-family: Axiforma, sans-serif;
  font-weight: 500;
  margin: 0;
  padding: 0
}

#appTitle {
  color: black;
  margin-bottom: 5%;
}

#registerButton {
  width: auto;
}

#subtitle {
  margin-top: 5em;
  font-size: 1.2em;
}

#arrowIcon {
  background-color: #d2c3ff !important;
}

#arrowIcon:hover {
  background-color: #003737 !important;
}

.hoverButton {
  border-radius: 35px !important;
  height: 70px;
  background-color: #d2c3ff !important;
}

.hoverButtonDisabled {
  border-radius: 35px !important;
  height: 70px;
  background-color: gray !important;
}

.hoverButton :hover {
  border-radius: 35px !important;
  height: 70px;
  background-color: #003737 !important;
}

.hoverButtonText {
  color: #003732;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 70px;
  margin: auto;
  font-family: TradeGothic;
  font-size: 20px;
}

.hoverButtonText:hover {
  color: #FFFFFF !important;
}

.hoverButtonLogin {
  height: 70px;
  width: 70%;
  border-radius: 35px;
  background-color: #d2c3ff !important;
}

.hoverSmallButton {
  margin: 3px;
  height: 40px;
  width: 160px;
  border-radius: 20px;
  background-color: #d2c3ff !important;
}

.hoverSmallButton :hover {
  height: 40px;
  border-radius: 20px;
  background-color: #003737 !important;
}

.hoverButtonLogin :hover {
  height: 70px;
  border-radius: 35px;
  background-color: #003737 !important;
}

.hoverSmallButtonText {
  color: #003732;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 40px;
  margin: auto;
  font-family: tradeGothic;
  font-size: 19px;
}


.hoverSmallButtonText:hover {
  color: #FFFFFF !important;
}

.hoverButtonLoginText {
  color: #003732;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 70px;
  margin: auto;
  font-family: tradeGothic;
  font-size: 19px;
}

.hoverButtonLoginTextDisabled {
  color: white;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 70px;
  margin: auto;
  font-family: tradeGothic;
  font-size: 19px;
}

.hoverButtonLoginText:hover {
  color: #FFFFFF !important;
}

.hoverButtonModal {
  height: 50px;
  width: 150px;
  border-radius: 35px;
  background-color: #d2c3ff !important;
}

.hoverButtonModal:hover {
  height: 50px;
  width: 150px;
  border-radius: 35px;
  background-color: #003737 !important;
}

.hoverButtonModalText {
  color: #003732;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 50px;
  margin: auto;
  font-family: tradeGothic;
  font-size: 19px;
}

.hoverButtonModalText:hover {
  color: white;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 50px;
  margin: auto;
  font-family: tradeGothic;
  font-size: 19px;
}

.menuItem {
  font-family: TradeGothic;
  font-size: 30px;
  color: #003737;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
}

.container {
  display: flex;
  /*align-items: center;*/
  justify-content: center;
  width: 81.25vw;
  margin: 0 auto;
}

.containerForIpad {
  display: flex;
  /*align-items: center;*/
  justify-content: center;
  width: 90vw;
  margin: 0 auto;
}

.hoverButton2 {
  border-radius: 35px !important;
  height: 70px;
  background-color: #d2c3ff !important;
}

.hoverButton2:hover {
  border-radius: 35px !important;
  height: 70px;
  background-color: #003737 !important;
}

.hoverButtonText2 {
  color: #003732;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 70px;
  margin: auto;
  font-family: tradeGothic;
  font-size: 19px;
}

.hoverButtonText2:hover {
  color: #FFFFFF !important;
}


.create-book-photo-picker {
  /* border-radius: 50%; */
  background-color: gold;
  display: flex;
  align-items: center;
  justify-content: center;
  /* overflow: hidden; */
  position: relative;
  z-index: 99;
  box-shadow: 0 4px 3px rgba(0, 0, 0, 0.356);
  margin-bottom: 0.5rem;
  margin-right: auto;
  margin-left: auto;
  margin-top: 3rem;
}

.create-book-photo-input {
  opacity: 0;
  width: 100%;
  height: 100%;
}

.create-book-photo-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.create-book-image-preview-container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 99999999;
}

.create-book-image-preview-image-box {
  /* width: 550px;
  height: 350px; */
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.image {
  object-fit: fill;
}

.create-book-image-input-container {
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.create-book-image-preview-icon-container {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: tomato;
  position: absolute;
  top: -15px;
  right: -15px;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
}
