.navContainer {
    width: 100vw;
    background-color: white;
    margin: 0 auto;
    position: fixed;
    top: 0;
    left: 0;
    padding: 26px 8.75vw;
    z-index: 1000;
    height: 36px;
}

a {
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    color: inherit;
    text-decoration: none;
    cursor: pointer;
}

nav {
    z-index: 200;
}

.btn:hover:not(.ouvert) .barre1 {
    -webkit-animation: animation-bouton-menu-first .8s ease forwards;
    -o-animation: animation-bouton-menu-first .8s ease forwards;
    animation: animation-bouton-menu-first .8s ease forwards;
}

.btn:hover:not(.ouvert) .barre2 {
    -webkit-animation: animation-bouton-menu-second .8s ease .2s forwards;
    -o-animation: animation-bouton-menu-second .8s ease .2s forwards;
    animation: animation-bouton-menu-second .8s ease .2s forwards;
}

.btn:hover {
    text-decoration: none;
}

.btn {
    position: relative;
    display: inline-block;
    width: 7rem;
    height: 0.6rem;
    margin-left: 7rem;
    transition: all .3s ease;
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    border: none;
    background: 0 0;
    z-index: 106;
}

.barre {
    width: 2rem;
    height: 2px;
    display: block;
    background-color: #00373c;
    transition: all .3s ease;
    overflow: visible
}

.barre1 {
    width: 60%;
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(0) rotate(0);
}

.barre2 {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateY(0) rotate(0);
}


.btn.ouvert .barre {
    width: 2rem;
    -webkit-transition: width .3s ease, top .3s ease .3s, bottom .3s ease .3s, -webkit-transform .3s ease .3s;
    transition: width .3s ease, top .3s ease .3s, bottom .3s ease .3s, -webkit-transform .3s ease .3s;
    -o-transition: width .3s ease, top .3s ease .3s, bottom .3s ease .3s, -o-transform .3s ease .3s;
    transition: width .3s ease, top .3s ease .3s, bottom .3s ease .3s, transform .3s ease .3s;
    transition: width .3s ease, top .3s ease .3s, bottom .3s ease .3s, transform .3s ease .3s, -webkit-transform .3s ease .3s, -o-transform .3s ease .3s;
}

.btn.ouvert .barre:nth-child(1) {
    top: 50%;
    -webkit-transform: translateY(-50%) rotate(
            -45deg
    );
    -ms-transform: translateY(-50%) rotate(-45deg);
    -o-transform: translateY(-50%) rotate(-45deg);
    transform: translateY(-50%) rotate(
            -45deg
    );
}

.btn.ouvert .barre:nth-child(2) {
    bottom: 50%;
    -webkit-transform: translateY(50%) rotate(
            45deg
    );
    -ms-transform: translateY(50%) rotate(45deg);
    -o-transform: translateY(50%) rotate(45deg);
    transform: translateY(50%) rotate(
            45deg
    );
}





.p-menu {
    position: fixed;
    top: -100%;
    left: 0;
    z-index: 103;
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    -webkit-transition: all .4s ease .1s;
    -o-transition: all .4s ease .1s;
    transition: all .4s ease .1s;
}

.p-menu.ouvert {
    top:  80px;
    background-color: #fff;
    -webkit-transition: top .6s ease .3s,background-color .6s ease;
    -o-transition: top .6s ease .3s,background-color .6s ease;
    transition: top .6s ease .3s,background-color .6s ease;
}

.p-menu_content_nav li .link {
    display: inline-block;
    font-weight: 700;
    font-size: 22px;
    line-height: 22px;
    color: #00373c;
}

.menuItem {
    font-size: calc(1.9vw + 3px);
}

.col-xs-12 {
    width: 100%;
}

.flex.justify-between {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.flex.align-center {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}
.p-menu_content {
    background-color: #fff;
}
.flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}


@-webkit-keyframes animation-bouton-menu-first {
    from {
        width: 60%
    }
    25% {
        width: 0
    }
    50% {
        width: 0
    }
    75% {
        width: 0
    }
    100% {
        width: 60%
    }
}

@-o-keyframes animation-bouton-menu-first {
    from {
        width: 60%
    }
    25% {
        width: 0
    }
    50% {
        width: 0
    }
    75% {
        width: 0
    }
    100% {
        width: 60%
    }
}

@keyframes animation-bouton-menu-first {
    from {
        width: 60%
    }
    25% {
        width: 0
    }
    50% {
        width: 0
    }
    75% {
        width: 0
    }
    100% {
        width: 60%
    }
}


@-webkit-keyframes animation-bouton-menu-second {
    from {
        width: 2rem
    }
    33% {
        width: 60%
    }
    66% {
        width: 0
    }
    100% {
        width: 2rem
    }
}

@-o-keyframes animation-bouton-menu-second {
    from {
        width: 2rem
    }
    33% {
        width: 60%
    }
    66% {
        width: 0
    }
    100% {
        width: 2rem
    }
}

@keyframes animation-bouton-menu-second {
    from {
        width: 2rem
    }
    33% {
        width: 60%
    }
    66% {
        width: 0
    }
    100% {
        width: 2rem
    }
}